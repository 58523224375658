.animated.rounded {
  border-radius: 5px;
}

.btn-hover a::before {
  border-radius: 5px;
}

.btn-hover a::after {
  border-radius: 5px;
}

.crt-logo.crt-tag {
  z-index: 0 !important;
}

.ff-6418c89413090a3734da14ef__title {
  font-family: 'Nunito' !important;
  font-style: normal !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  margin: 8px 0 30px !important;
  color: #433f3f !important;
  position: relative !important;
  display: inline-block !important;
  padding: 0 0 23px !important;
  line-height: 1 !important;
}

.ff-6418c89413090a3734da14ef__title::before {
  position: absolute;
  background-color: #070508;
  height: 3px;
  width: 70px;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.ff-6418c89413090a3734da14ef__subtitle {
  font-size: 15px !important;
  @media #{$lg-layout, $xl-layout, $xx-layout, $max-layout} {
    font-size: 18px !important;
  }
}

.ff-6418c89413090a3734da14ef__button {
  font-family: 'Nunito' !important;
  font-weight: 500;
  line-height: 1;
  width: auto;
  height: auto;
  padding: 17px 55px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: none;
  background-color: #070508;
}

.ff-6418c89413090a3734da14ef__label {
  font-family: 'Nunito' !important;
}

.ff-6418c89413090a3734da14ef__form {
  padding: 0 20px 90px 20px !important;
}

.ff-64227921c5a879f375267033__title div div span {
  font-family: 'Nunito' !important;
  font-style: normal !important;
  font-size: 34px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
  position: relative !important;
  display: inline-block !important;
  line-height: 1 !important;
}

.ff-64227921c5a879f375267033__title div div span:first-of-type {
  margin-right: 5px !important;
}

.ff-64227921c5a879f375267033__title div div span:last-of-type {
  margin-left: 5px !important;
}

.ff-64227921c5a879f375267033__subtitle {
  font-size: 15px !important;
  @media #{$lg-layout, $xl-layout, $xx-layout, $max-layout} {
    font-size: 18px !important;
  }
}

.ff-64227921c5a879f375267033__button {
  font-family: 'Nunito' !important;
  font-weight: 500;
  line-height: 1;
  width: auto;
  height: auto;
  padding: 17px 55px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: none;
  background-color: #070508;
}

.ff-64227921c5a879f375267033__label {
  font-family: 'Nunito' !important;
}

.ff-64227921c5a879f375267033__left {
  padding: 0 !important;
}

.ff-64227921c5a879f375267033__right {
  padding: 0 0 0 5.5% !important;
  max-height: 400px !important;
}

.ff-64227921c5a879f375267033__container {
  margin-bottom: 90px !important;
  padding: 0 20px 0 20px !important;
}