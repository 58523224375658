*,
*:before,
*:after {
	box-sizing: border-box;
}

button,
input,
select,
textarea {
	font: inherit;
}

a {
	color: inherit;
}

/* End basic CSS override */

* {
	scrollbar-width: 0;
}

*::-webkit-scrollbar {
	background-color: transparent;
	width: 12px;
}

*::-webkit-scrollbar-thumb {
	border-radius: 99px;
	background-color: #ddd;
	border: 4px solid #fff;
}

.modal {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	background-color: rgba(#000, 0.25);
  	height: 100vh;
	justify-content: center;
	padding-bottom: 7.5vh;
}

.modal-center {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.modal-top {
  align-items: flex-start;
}

.modal-bottom {
  align-items: flex-end;
}

.modal-left {
  margin-left: 1rem !important;
  margin-right: auto !important;
}

.modal-right {
  margin-left: auto !important;
  margin-right: 1rem !important;
}

.modal-container {
	max-height: 50vh;
	width: 500px;
	margin-left: auto;
	margin-right: auto;
	background-color: #fff;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
	margin-top: 1rem;
	margin-bottom: 1rem;
	@media (max-width: 600px) {
		width: 90%;
	}
}

.modal-container-header {
	padding: 8px 16px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;

	& i {
		font-size: 30px;
		margin-right: 1.5rem;
	}

	& h2 {
		margin: 0;
	}
}

.modal-container-title {
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 1;
	font-weight: 700;
	font-size: 1.125;
	svg {
		width: 32px;
		height: 32px;
		color: #750550;
	}
}

.modal-container-body {
	padding: 1rem 2rem;
	overflow-y: auto;
	font-size: 20px !important;
}

.modal-container-footer {
	padding: 20px 32px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid #ddd;
	gap: 12px;
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: -51px;
		left: 24px;
		right: 24px;
		height: 50px;
		flex-shrink: 0;
		pointer-events: none;
	}
}

.icon-button {
	padding: 0;
	border: 0;
	background-color: transparent;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	cursor: pointer;
	border-radius: 8px;
	transition: 0.15s ease;
	svg {
		width: 24px;
		height: 24px;
	}

	&:hover,
	&:focus {
		background-color: #dfdad7;
	}
}
